<article class="instagram-comments-moderation" [ngStyle]="instagramCommentsService.instagramCommentLength > 0 ? { 'min-height': 'auto' } : { 'min-height': '530px' }">
  <section
    class="border-wrapper"
    [ngClass]="{
      'on-header': instagramCommentsService.publishedContent !== null && !instagramCommentsService.publishedContent,
      'off-header': instagramCommentsService.publishedContent === null || instagramCommentsService.publishedContent
    }"
  >
    <header class="instagram-comments-warning-header flex-between bold">
      <div class="flex-wrapper" style="gap: 4px"><img src="assets/icon/alert_diamond.svg" /> Publish comments to show</div>
      <img (click)="instagramCommentsService.publishedContent = null" class="instagram-comments-warning-close" src="assets/icon/x_small_white.svg" />
    </header>
    <div class="instagram-comments-warning">
      There are no published comments at the moment. Since there are no comments to display, the widget will not appear. Please review and publish comments to show them in the
      widget.
    </div>
  </section>

  <section
    class="border-wrapper instagram-comments-wrapper"
    [ngClass]="{
      'on-header': instagramCommentsService.publishedContent !== null && !instagramCommentsService.publishedContent,
      'off-header': instagramCommentsService.publishedContent === null || instagramCommentsService.publishedContent
    }"
  >
    <section class="instagram-comments-filter flex-between">
      <div class="flex-wrapper" style="gap: 8px">
        <div class="filter-wrapper">
          <select [(ngModel)]="instagramCommentsService.searchFor" class="filter-select">
            <option [ngValue]="'username'">User Name</option>
            <option [ngValue]="'content'">Content</option>
          </select>
          <span class="filter-split"></span>
          <form class="filter-form">
            <button (click)="instagramCommentsService.getManageInstagramComments()"><img src="assets/icon/search_gray.svg" /></button>
            <input
              (keydownEnter)="instagramCommentsService.getManageInstagramComments()"
              [(ngModel)]="instagramCommentsService.searchKeyword"
              [ngModelOptions]="{ standalone: true }"
              placeholder="Search"
            />
          </form>
        </div>
        <div class="filter-wrapper">
          <select [(ngModel)]="instagramCommentsService.sortBy" (ngModelChange)="instagramCommentsService.getManageInstagramComments()" class="filter-select sort">
            <option [ngValue]="'newest_comments'">Newest Comments First</option>
            <option [ngValue]="'oldest_comments'">Oldest Comments First</option>
            <option [ngValue]="'newest_posts'">Recent Posts First</option>
            <option [ngValue]="'oldest_posts'">Oldest Posts First</option>
          </select>
        </div>
      </div>
      <alpha-checkbox [(ngModel)]="instagramCommentsService.isPublished" (ngModelChange)="instagramCommentsService.getManageInstagramComments()"
        >Published comments only</alpha-checkbox
      >
    </section>

    <section class="instagram-comments-table">
      <header class="instagram-comments-table-header">
        <div class="comment">Comment</div>
        <div class="action">Action</div>
      </header>
      @if(instagramCommentsService.instagramCommentLength > 0) {
      <section class="instagram-comments-table-body">
        @for (comment of instagramCommentsService.instagramComments; track $index) {
        <div class="instagram-comments-table-row" [ngClass]="{ 'un-publish': !comment.is_published }">
          <div class="comment">
            <div class="comment-info flex-wrapper">
              <div>{{ '@' }}{{ comment.username }}</div>
              <span class="split-circle"></span>
              <div>{{ comment.timestamp | date : 'MMM d, y h:mm a' }}</div>
              <span class="split-circle"></span>
              <div>{{ comment.like_count }} Likes</div>
              <span class="split-circle"></span>
              <a href="{{ comment.media_permalink }}" target="_blank" class="flex-wrapper">View Instagram Post <img src="assets/icon/external_link_grey.svg" /></a>
            </div>
            <div #commentContent class="comment-content">{{ comment.text }}</div>
          </div>
          <div class="action">
            <button (click)="instagramCommentsService.onTogglePublished(comment, comment.is_published ? false : true)" class="ic-btn ic-btn-select">
              {{ comment.is_published ? 'Unpublish' : 'Publish' }}
            </button>
            <div class="flex-wrapper" style="gap: 5px">
              <img [src]="comment.is_published ? 'assets/icon/publish_eye.svg' : 'assets/icon/un_publish_eye.svg'" /> {{ comment.is_published ? 'Shown' : 'Hidden' }}
            </div>
          </div>
        </div>
        } @empty {
        <div class="instagram-comments-table-empty">
          <img src="assets/image/tutorial/feed-empty.png" />
          <div class="bold">No results found</div>
        </div>
        }
      </section>
      }
    </section>
    <section class="instagram-comments-footer">
      <mat-paginator
        #paginator
        class="ic-paginator"
        (page)="instagramCommentsService.getManageInstagramComments($event)"
        [pageSizeOptions]="[10, 20, 100]"
        [disabled]="instagramCommentsService.manageInstagramCommentsLoading"
        showFirstLastButtons
        aria-label="Select page"
        ><input class="admin-input" />
      </mat-paginator>
    </section>
  </section>
  @if(instagramCommentsService.instagramCommentsAccount.status === instagramCommentsService.InstagramCommentsAccountStatus.CONNECTED && (!instagramCommentsService.instagramComments
  || instagramCommentsService.instagramCommentLength === 0)) {
  <section class="border-wrapper not-connected retry">
    <div class="not-connected-img">
      <img style="padding: 0" src="assets/image/instagram-comments/not_comments.png" />
    </div>
    @if(!instagramCommentsService.imported) {
    <div class="not-connected-description">
      <header class="bold">No Instagram comments have been fetched</header>
      <div style="text-wrap: balance">
        This might be because there are no comments to fetch or an issue occurred during the process. Please try fetching the comments again or contact us for assistance.
      </div>
    </div>
    <div class="flex-wrapper">
      <button onclick="location.href='mailto:support@alph.kr';" class="ic-btn ic-btn-cancel small">Contact Us</button>
      <button (click)="retryInstagramComments()" class="ic-btn ic-btn-confirm small">Retry</button>
    </div>
    } @else {
    <div class="progress-area"><div class="progress"></div></div>
    }
  </section>
  } @if (instagramCommentsService.instagramCommentsAccount.status !== instagramCommentsService.InstagramCommentsAccountStatus.CONNECTED) {
  <section class="border-wrapper not-connected">
    <div class="not-connected-img">
      <img src="assets/icon/ic_not_connented.svg" />
    </div>
    <div class="not-connected-description">
      <header class="bold">Link your Instagram account</header>
      <div>To fetch Instagram comments, you need to link your account.</div>
    </div>
    <button (click)="goToSettings()" class="ic-btn ic-btn-confirm small">Link Account Here</button>
  </section>
  }
</article>
