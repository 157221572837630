<article id="dialog-connect-page">
  <img src="/assets/icon/facebook_connect.svg" />
  <section class="border-wrapper" style="width: 100%">
    <mat-radio-group (change)="onSelectPage($event)" name="item" class="custom-radio">
      @for (page of pageList; track $index) {
      <mat-radio-button [value]="page" name="item" class="custom-radio-btn">
        <div class="bold">{{ page.facebook_page_name }}</div>
        <div class="page-id">{{ page.facebook_page_id }}</div>
      </mat-radio-button>
      }
    </mat-radio-group>
  </section>
  <section class="page-btns" style="gap: 10px">
    <div (click)="onCancel()" class="ic-btn ic-btn-cancel">Cancel</div>
    <div (click)="onConfirm()" class="ic-btn ic-btn-confirm">Select</div>
  </section>
</article>
