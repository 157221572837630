import { Component } from '@angular/core';
import { InstagramCommentsService } from '../instagram-comments.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-connect-page',
  standalone: true,
  imports: [CommonModule, MatRadioModule],
  templateUrl: './dialog-connect-page.component.html',
  styleUrl: './dialog-connect-page.component.less',
})
export class DialogConnectPageComponent {
  constructor(private instagramCommentsService: InstagramCommentsService, private dialogRef: MatDialogRef<DialogConnectPageComponent>) {
    this.getPageList();
  }

  pageList: FacebookPage[] = [];
  selectedPage?: FacebookPage;

  getPageList() {
    this.instagramCommentsService.getFacebookPages().subscribe((response) => (this.pageList = response.body));
  }

  onSelectPage(event: { value: FacebookPage }) {
    this.selectedPage = event.value;
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(this.selectedPage);
  }
}
interface FacebookPage {
  facebook_page_name: string;
  facebook_page_id: number;
  is_selected: boolean;
}
