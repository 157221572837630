import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
declare var FB: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookSdkService {
  private renderer!: Renderer2;
  private isSdkLoaded: boolean = false;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadSdk(appId: string, version: string = 'v20.0'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSdkLoaded) {
        resolve();
        return;
      }

      const script = this.renderer.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        FB.init({
          appId: appId,
          xfbml: true,
          version: version,
        });
        FB.AppEvents.logPageView();
        this.isSdkLoaded = true;
        resolve();
      };
      script.onerror = (error: any) => {
        reject(error);
      };
      this.renderer.appendChild(document.head, script);
    });
  }
}
