import { enableProdMode } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: 'https://13656cbede98614c4f0a4b38fab92d61@o4507729392304128.ingest.us.sentry.io/4507734170337280',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/shop\.a-review\.co/, /^https:\/\/review\.a-review\.co/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    const hostname = window.location.hostname;
    if (['front.global-alpha-dev.click', 'localhost'].includes(hostname)) return null;
    if (event.message && event.message.includes('NG0100')) return null;

    const frames = event.exception?.values?.[0]?.stacktrace?.frames;
    if (frames?.some((frame) => frame.function === 'onClickEnter')) return null;

    return event;
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [{ provide: LOCALE_ID, useValue: 'en-US' }],
  })
  .catch((err) => console.error(err));
