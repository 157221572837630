<article id="instagram-comments">
  <mat-tab-group #tabGroup fitInkBarToContent class="instagram-comments-tabs">
    <mat-tab label="Moderation">
      @if (instagramCommentsService.instagramCommentsAccount) {
      <app-instagram-comments-moderation (changeTabs)="changeTabs($event)"></app-instagram-comments-moderation>
      }
    </mat-tab>

    <mat-tab label="Settings">
      @if (instagramCommentsService.instagramCommentsAccount) {
      <app-instagram-comments-settings></app-instagram-comments-settings>
      }
    </mat-tab>
  </mat-tab-group>
</article>
