import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstagramCommentsComponent } from './instagram-comments/instagram-comments.component';
import { instagramCommentsGuard } from './guard/instagram-comments.guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'write',
    loadChildren: () => import('./write/write.module').then((m) => m.WriteModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'super',
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
  },
  { path: 'instagram-comments', component: InstagramCommentsComponent, canActivate: [instagramCommentsGuard] },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
