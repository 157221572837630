import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { PaginationHandler } from 'src/app/shared/handler/pagination-handler';
import { SharedModule } from 'src/app/shared/shared.module';
import { inOutAnimation } from 'src/app/admin/animations';
import { InstagramCommentsService } from '../instagram-comments.service';

@Component({
  selector: 'app-instagram-comments-moderation',
  standalone: true,
  imports: [CommonModule, SharedModule, MatPaginatorModule],
  templateUrl: './instagram-comments-moderation.component.html',
  styleUrl: './instagram-comments-moderation.component.less',
  animations: [inOutAnimation],
})
export class InstagramCommentsModerationComponent {
  @ViewChild('paginator') paginator?: MatPaginator;
  @Output() changeTabs = new EventEmitter<boolean>();

  constructor(public instagramCommentsService: InstagramCommentsService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.instagramCommentsService.getPublishedInstagramComments().subscribe((response) => (this.instagramCommentsService.publishedContent = response.body));
  }

  ngAfterViewInit(): void {
    this.instagramCommentsService.paginationHandler = new PaginationHandler(this.paginator!, 0, 10);
    let trigger = this.instagramCommentsService.instagramCommentsAccount.status !== this.instagramCommentsService.InstagramCommentsAccountStatus.CONNECTED;
    if (trigger) return;
    this.instagramCommentsService.getManageInstagramComments();
    this.cdr.detectChanges();
  }

  retryInstagramComments() {
    this.instagramCommentsService.getInitialData().then(() => this.instagramCommentsService.getManageInstagramComments());
    this.cdr.detectChanges();
  }

  goToSettings() {
    this.changeTabs.emit(true);
  }
}
