import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { InstagramCommentsService } from '../instagram-comments.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConnectPageComponent } from '../dialog-connect-page/dialog-connect-page.component';
import { inOutAnimation } from 'src/app/admin/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { cloneDeep } from 'lodash';
import { GenericService } from 'src/app/service/generic.service';
@Component({
  selector: 'app-instagram-comments-settings',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './instagram-comments-settings.component.html',
  styleUrl: './instagram-comments-settings.component.less',
  animations: [inOutAnimation],
})
export class InstagramCommentsSettingsComponent {
  instagramCommentsSettings!: InstagramCommentsSettings;
  originInstagramCommentsSettings!: InstagramCommentsSettings;
  constructor(public instagramCommentsService: InstagramCommentsService, private genericService: GenericService) {
    this.instagramCommentsSettings = {
      id: 0,
      shop_name: '',
      comment_date: false,
      commenter_name: false,
      commenter_profile_image: false,
      number_of_likes: false,
      instagram_profile_button: false,
      instagram_post_button: false,
      color: '',
    };
  }

  ngOnInit(): void {
    this.getWidgetSettings();
  }

  facebookLogin() {
    this.instagramCommentsService.facebookLogin().then(() => this.instagramCommentsService.getAccountStatus());
  }

  getWidgetSettings() {
    this.instagramCommentsService.getWidgetSettings().subscribe((response) => {
      this.instagramCommentsSettings = response.body;
      this.originInstagramCommentsSettings = cloneDeep(this.instagramCommentsSettings!);
    });
  }

  updateWidgetSettings() {
    this.instagramCommentsService.updateWidgetSettings(this.instagramCommentsSettings!).subscribe((response) => {
      if (response.ok) this.getWidgetSettings();
      this.genericService.openSnackBar('Saved');
    });
  }

  resetWidgetSettings() {
    this.instagramCommentsSettings = this.originInstagramCommentsSettings;
    this.originInstagramCommentsSettings = cloneDeep(this.instagramCommentsSettings);
  }
}
interface InstagramCommentsSettings {
  id: number;
  shop_name?: string;
  comment_date: boolean;
  commenter_name: boolean;
  commenter_profile_image: boolean;
  number_of_likes: boolean;
  instagram_profile_button: boolean;
  instagram_post_button: boolean;
  color: string;
}
