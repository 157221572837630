import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { InstagramCommentsService } from '../instagram-comments/instagram-comments.service';
import { inject } from '@angular/core';
import { Observable, catchError, delay, expand, map, of, reduce, retryWhen, switchMap, take, takeWhile, throwError, timer } from 'rxjs';
import { BackendService } from '../service/backend.service';
import { HttpResponse } from '@angular/common/http';

export const instagramCommentsGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const instagramCommentsService = inject(InstagramCommentsService);
  const router = inject(Router);
  const backendService = inject(BackendService);

  const handleError = (error: any): Observable<boolean> => {
    console.error('Error in guard:', error);
    router.navigate(['/home/main']);
    return of(false);
  };

  const retryRequest = (url: string, maxRetries: number): Observable<HttpResponse<any>> => {
    let attempts = 0;
    return backendService.select(url, {}, {}, true, instagramCommentsService.sessionToken).pipe(
      expand((response: HttpResponse<any>) => {
        if (response.body === null && attempts < maxRetries) {
          attempts++;
          return timer(1000).pipe(switchMap(() => backendService.select(url, {}, {}, true, instagramCommentsService.sessionToken)));
        } else {
          return of(response);
        }
      }),
      takeWhile((response: HttpResponse<any>) => response.body === null && attempts < maxRetries, true),
      reduce((_, response) => response),
    );
  };

  return instagramCommentsService.getSessionToken().pipe(
    switchMap((token): Observable<boolean> => {
      //console.log('Got session token:', token);
      return instagramCommentsService.verifySessionToken(token);
    }),
    switchMap((isTokenValid): Observable<boolean> => {
      //console.log('Token valid:', isTokenValid);
      if (!isTokenValid) {
        router.navigate(['/home/main']);
        return of(false);
      }
      return instagramCommentsService.getQueryParamsString().pipe(
        switchMap((paramsString): Observable<HttpResponse<any>> => {
          const url = `instagram-comments/shopify?${paramsString}`;
          return retryRequest(url, 5).pipe(
            catchError((error): Observable<HttpResponse<any>> => {
              console.error('Request failed:', error);
              return throwError(() => new Error('Request failed'));
            }),
          );
        }),
        switchMap((response: HttpResponse<any>): Observable<boolean> => {
          const body = response.body;
          return instagramCommentsService.validateShopPlatformId(body.shop_platform_id).pipe(
            map((isValid): boolean => {
              if (!isValid) {
                router.navigate(['/home/main']);
              }
              return isValid;
            }),
          );
        }),
        catchError(handleError),
      );
    }),
    catchError(handleError),
  );

  // return instagramCommentsService.getSessionToken().pipe(
  //   switchMap((token) => {
  //     // console.log('Got session token:', token);
  //     return instagramCommentsService.verifySessionToken(token);
  //   }),
  //   switchMap((isTokenValid) => {
  //     // console.log('Token valid:', isTokenValid);
  //     if (!isTokenValid) {
  //       router.navigate(['/home/main']);
  //       return of(false);
  //     }
  //     return instagramCommentsService.getQueryParamsString().pipe(
  //       switchMap((paramsString) => {
  //         const url = `instagram-comments/shopify?${paramsString}`;
  //         return backendService.select(url, {}, {}, true, instagramCommentsService.sessionToken);
  //       }),
  //       switchMap((response: any) => {
  //         const body = response.body;
  //         return instagramCommentsService.validateShopPlatformId(body.shop_platform_id).pipe(
  //           map((isValid) => {
  //             // console.log('Shop platform ID valid:', isValid);
  //             if (!isValid) {
  //               router.navigate(['/home/main']);
  //             }
  //             return isValid;
  //           }),
  //         );
  //       }),
  //     );
  //   }),
  //   catchError((error) => {
  //     console.error('Error in guard:', error);
  //     router.navigate(['/home/main']);
  //     return of(false);
  //   }),
  // );
};
