<section class="instagram-comments-settings">
  <section class="instagram-comments-settings-account border-wrapper">
    <header class="bold">Instagram Account</header>
    @if (instagramCommentsService.instagramCommentsAccount.status === instagramCommentsService.InstagramCommentsAccountStatus.NOT_LOGGED_IN) {
    <div>
      To fetch Instagram comments, your account must be a professional one. If not,
      <a href="https://help.instagram.com/502981923235522?cms_id=502981923235522" target="_blank">here</a>'s how to switch. If you already have a professional account, log in with
      the Facebook account linked to your Instagram.
    </div>
    }
    <section class="instagram-comments-settings-connected flex-wrapper" [ngSwitch]="instagramCommentsService.instagramCommentsAccount.status">
      <ng-container *ngSwitchCase="instagramCommentsService.InstagramCommentsAccountStatus.NOT_LOGGED_IN">
        <div (click)="facebookLogin()" class="instagram-comments-settings-login flex-wrapper">
          <img src="assets/icon/instagram-curation-facebook-blue.svg" />
          Connect Facebook
        </div>
      </ng-container>
      @if (instagramCommentsService.instagramCommentsAccount.status !== instagramCommentsService.InstagramCommentsAccountStatus.NOT_LOGGED_IN) {
      <img src="assets/icon/instagram-curation-facebook-blue.svg" />
      }
      <ng-container *ngSwitchCase="instagramCommentsService.InstagramCommentsAccountStatus.NO_PAGE">
        <div class="flex-between">
          <div>
            <div class="bold">Facebook Page</div>
            <div class="instagram-comments-settings-connected-info flex-wrapper"><img src="assets/icon/info_red.svg" /> Select Facebook Page to use this feature.</div>
          </div>
          <button (click)="instagramCommentsService.openDialogConnectPage()" class="ic-btn ic-btn-select">Select Facebook Page</button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="instagramCommentsService.InstagramCommentsAccountStatus.NOT_SELECTED_PAGE">
        <div class="flex-between">
          <div>
            <div class="bold">Facebook Page</div>
            <div class="instagram-comments-settings-connected-info flex-wrapper"><img src="assets/icon/info_red.svg" /> Select Facebook Page to use this feature.</div>
          </div>
          <button (click)="instagramCommentsService.openDialogConnectPage()" class="ic-btn ic-btn-select">Select Facebook Page</button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="instagramCommentsService.InstagramCommentsAccountStatus.NOT_CONNECTED_INSTAGRAM">
        <div class="flex-between">
          <div>
            <div class="bold">{{ instagramCommentsService.instagramCommentsAccount.facebook_page_name }}</div>
            <div class="instagram-comments-settings-connected-info flex-wrapper">
              <img src="assets/icon/info_red.svg" /> There are no Instagram professional accounts linked to the selected Facebook page.
            </div>
          </div>
          <button (click)="instagramCommentsService.openDialogConnectPage()" class="ic-btn ic-btn-select">Select Facebook Page</button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="instagramCommentsService.InstagramCommentsAccountStatus.CONNECTED">
        <div class="flex-between">
          <div>
            <div class="bold flex-wrapper">
              {{ instagramCommentsService.instagramCommentsAccount.facebook_page_name }} <span class="instagram-comments-settings-connected-icon">Connected</span>
            </div>
            <div (click)="instagramCommentsService.openDialogConnectPage()" class="instagram-comments-settings-connected-page">Select another Facebook page</div>
          </div>
          <button (click)="instagramCommentsService.facebookLogout()" class="ic-btn ic-btn-select">Disconnect</button>
        </div>
      </ng-container>
    </section>
  </section>
  <section class="border-wrapper instagram-comments-settings-lean">
    <header class="bold flex-between">
      How to Add a Widget to Your Store
      <span onclick="window.open('https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps?section=Desktop#app-blocks')">Learn More</span>
    </header>
    <div>
      <div>
        1. Go to the
        <a (click)="instagramCommentsService.goToThemeEditPage()">Shopify Theme Editor</a> and navigate to the page and section where you want to add the app block.
      </div>
      <div>2. On the sidebar, click "Add Block."</div>
      <div>3. From the drop-down menu in the Apps section, select the app block named "Instagram Comments."</div>
    </div>
  </section>
  <section class="instagram-comments-settings border-wrapper">
    <header class="bold">Widget Settings</header>
    <div class="instagram-comments-settings-shop">
      <header>Store Name</header>
      <input [ngModel]="instagramCommentsSettings.shop_name" class="admin-input" />
      <div class="instagram-comments-settings-summary">This store name will be displayed on the widget.</div>
    </div>
    <div class="instagram-comments-settings-component">
      <header class="instagram-comments-settings-title">Components to Show</header>
      <alpha-checkbox [(ngModel)]="instagramCommentsSettings.comment_date">Comment date</alpha-checkbox>
      <alpha-checkbox [(ngModel)]="instagramCommentsSettings.commenter_name">Commenter's name</alpha-checkbox>
      <alpha-checkbox [(ngModel)]="instagramCommentsSettings.commenter_profile_image">Commenter's profile image</alpha-checkbox>
      <alpha-checkbox [(ngModel)]="instagramCommentsSettings.number_of_likes">Number of likes on the comment</alpha-checkbox>
      <alpha-checkbox [(ngModel)]="instagramCommentsSettings.instagram_profile_button">Instagram profile button</alpha-checkbox>
      <alpha-checkbox [(ngModel)]="instagramCommentsSettings.instagram_post_button">Instagram post button</alpha-checkbox>
    </div>
    <!-- <div class="instagram-comments-settings-color">
      <header class="instagram-comments-settings-title">Theme Color</header>
      <alpha-color-picker
        [selectedColor]="instagramCommentsService.instagramCommentsSettings.color"
        (colorChange)="instagramCommentsService.instagramCommentsSettings.color = $event"
      ></alpha-color-picker>
    </div> -->
    <div class="flex-wrapper instagram-comments-settings-btn">
      <button (click)="resetWidgetSettings()" class="ic-btn ic-btn-cancel small">Cancel</button>
      <button (click)="updateWidgetSettings()" class="ic-btn ic-btn-confirm small">Save</button>
    </div>
  </section>
  <section class="instagram-comments-privacy border-wrapper">
    <header class="bold">Limit on the Number of Fetched Comments</header>
    <div>Only comments from the most recent 50 Instagram posts will be retrieved. If you need more comments, please contact us at support{{ '@' }}alph.kr.</div>
  </section>
</section>
