import { Component, ViewChild } from '@angular/core';
import { FacebookSdkService } from '../service/facebook-sdk.service';
import { InstagramCommentsService } from './instagram-comments.service';
import { CommonModule } from '@angular/common';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { inOutAnimation } from '../admin/animations';
import { InstagramCommentsSettingsComponent } from './instagram-comments-settings/instagram-comments-settings.component';
import { InstagramCommentsModerationComponent } from './instagram-comments-moderation/instagram-comments-moderation.component';

@Component({
  selector: 'app-instagram-comments',
  standalone: true,
  templateUrl: './instagram-comments.component.html',
  styleUrl: './instagram-comments.component.less',
  animations: [inOutAnimation],
  imports: [CommonModule, MatTabsModule, InstagramCommentsSettingsComponent, InstagramCommentsModerationComponent],
})
export class InstagramCommentsComponent {
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  constructor(private facebookSdkService: FacebookSdkService, public instagramCommentsService: InstagramCommentsService) {
    this.facebookSdkService
      //.loadSdk('2864332067053029') 테스트용 페이스북 앱
      .loadSdk('1518950832388469') // 검수용 앱
      .then(() => {
        console.log('Facebook SDK loaded successfully');
        this.instagramCommentsService.getAccountStatus();
      })
      .catch((error) => console.error('Error loading Facebook SDK', error));
  }

  changeTabs(change: boolean) {
    if (change) this.tabGroup.selectedIndex = TabType.SETTINGS;
  }
}

enum TabType {
  MODERATION = 0,
  SETTINGS = 1,
}
